<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.75 8V4C13.75 3.71667 13.846 3.479 14.038 3.287C14.2293 3.09567 14.4667 3 14.75 3H20.75C21.0333 3 21.2707 3.09567 21.462 3.287C21.654 3.479 21.75 3.71667 21.75 4V8C21.75 8.28333 21.654 8.52067 21.462 8.712C21.2707 8.904 21.0333 9 20.75 9H14.75C14.4667 9 14.2293 8.904 14.038 8.712C13.846 8.52067 13.75 8.28333 13.75 8ZM3.75 12V4C3.75 3.71667 3.846 3.479 4.038 3.287C4.22933 3.09567 4.46667 3 4.75 3H10.75C11.0333 3 11.271 3.09567 11.463 3.287C11.6543 3.479 11.75 3.71667 11.75 4V12C11.75 12.2833 11.6543 12.5207 11.463 12.712C11.271 12.904 11.0333 13 10.75 13H4.75C4.46667 13 4.22933 12.904 4.038 12.712C3.846 12.5207 3.75 12.2833 3.75 12ZM13.75 20V12C13.75 11.7167 13.846 11.479 14.038 11.287C14.2293 11.0957 14.4667 11 14.75 11H20.75C21.0333 11 21.2707 11.0957 21.462 11.287C21.654 11.479 21.75 11.7167 21.75 12V20C21.75 20.2833 21.654 20.5207 21.462 20.712C21.2707 20.904 21.0333 21 20.75 21H14.75C14.4667 21 14.2293 20.904 14.038 20.712C13.846 20.5207 13.75 20.2833 13.75 20ZM3.75 20V16C3.75 15.7167 3.846 15.479 4.038 15.287C4.22933 15.0957 4.46667 15 4.75 15H10.75C11.0333 15 11.271 15.0957 11.463 15.287C11.6543 15.479 11.75 15.7167 11.75 16V20C11.75 20.2833 11.6543 20.5207 11.463 20.712C11.271 20.904 11.0333 21 10.75 21H4.75C4.46667 21 4.22933 20.904 4.038 20.712C3.846 20.5207 3.75 20.2833 3.75 20Z"
      fill="#365FA3"
    />
  </svg>
</template>
